import React, { useState, useEffect } from "react";
import "../Chat.css";
import { axiosInstance, formatDate } from "../../utills";
import Pic from "../../assets/pic2.png";
import Delete from "../../assets/del.svg";
import moment from "moment";
import Loader from "../../assets/loader.gif";

function ChatList({
  selectedChat,
  updateSelectedChat,
  chatList,
  updateChatList,
  broadcastChatList,
  updateBroadcastChatList,
  trigger,
}) {
  const [load, updateLoad] = useState(false);

  const getChatList = () => {
    if (broadcastChatList.length === 0 && chatList.length === 0) {
      updateLoad(true);
    }
    const config = {
      method: "get",
      url: "/get-chat-list",
    };
    axiosInstance
      .request(config)
      .then((response) => {
        console.log(response?.data);
        const newBroadcastChatList = response?.data[0];
        const newChatList = response?.data[1];

        // Compare the new data with the existing state
        const isBroadcastChatListEqual =
          JSON.stringify(newBroadcastChatList) ===
          JSON.stringify(broadcastChatList);
        const isChatListEqual =
          JSON.stringify(newChatList) === JSON.stringify(chatList);

        // Update the state only if data has changed
        if (!isBroadcastChatListEqual) {
          updateBroadcastChatList(newBroadcastChatList);
        }
        if (!isChatListEqual) {
          updateChatList(newChatList);
        }

        // Hide the loader
        updateLoad(false);
      })
      .catch((error) => {
        console.log(error);
        // Hide the loader in case of error
        updateLoad(false);
      });
  };

  useEffect(() => {
    getChatList();
  }, []);

  useEffect(() => {
    getChatList();
  }, [trigger]);

  const del = (type, value) => {
    console.log("type and value are ", type, value);
    const config = {
      method: "get",
      url: `/delete-chat?type=${type}&value=${
        type === "solo" ? encodeURIComponent(value) : value
      }`,
    };
    axiosInstance
      .request(config)
      .then((response) => {
        console.log("List data is", response.data);
        updateBroadcastChatList(response?.data[0]);
        updateChatList(response?.data[1]);
         // Check if the deleted chat is the currently selected chat
      if (selectedChat.type === type) {
        let deletedIndex;
        if (type === "solo") {
          // Find the index of the chat with phone_number == value in chatList
          deletedIndex = chatList.findIndex(
            (chat) => chat.phone_number === value
          );
        } else if (type === "broadcast") {
          // Find the index of the chat with broadcastId == value in broadcastChatList
          deletedIndex = broadcastChatList.findIndex(
            (chat) => chat.broadcastId === value
          );
        }

        if (deletedIndex === selectedChat.index) {
          // The deleted chat is the currently selected one
          // Reset selectedChat
          updateSelectedChat({ type: null, index: 0 });
        } else if (deletedIndex < selectedChat.index) {
          // Adjust selectedChat index since the list has been shortened
          updateSelectedChat((prev) => ({
            ...prev,
            index: prev.index - 1,
          }));
        }
      }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="chat-list">
      {load && (
        <div
          style={{
            position: "absolute",
            zIndex: 1,
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={Loader}
            alt="Loading..."
            style={{ width: 30, height: 30 }}
          />
        </div>
      )}
      {broadcastChatList?.map((chat, index) => (
        <button
          key={index}
          onClick={() =>
            updateSelectedChat({ type: "broadcast", index: index })
          }
          style={{
            backgroundColor:
              selectedChat.type === "broadcast" && selectedChat.index === index
                ? "lightblue"
                : "rgba(0,0,0,0)",
          }}
          className="chat-tile"
        >
          <div className="chat-pic">
            <img src={Pic} alt="pic" style={{ width: 35 }} />
          </div>
          <div style={{ flex: 1, paddingLeft: 10 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div className="chat-inline">{chat?.channelName}</div>
              <button
                className="del"
                onClick={(e) => {
                  e.stopPropagation(); // Prevent triggering onClick of parent button
                  del(
                    "broadcast",
                    broadcastChatList[index] &&
                      broadcastChatList[index].broadcastId
                  );
                }}
              >
                <img src={Delete} alt="" />
              </button>
            </div>
            <div className="chat-inline-end" style={{ fontSize: 12 }}>
              {moment(chat.timestamp).fromNow()}
            </div>
          </div>
        </button>
      ))}
      {chatList?.map((chat, index) => (
        <button
          key={index}
          onClick={() => updateSelectedChat({ type: "solo", index: index })}
          style={{
            backgroundColor:
              selectedChat.type === "solo" && selectedChat.index === index
                ? "lightblue"
                : "rgba(0,0,0,0)",
          }}
          className="chat-tile"
        >
          <div className="chat-pic">
            <img src={Pic} alt="pic" style={{ width: 35 }} />
          </div>
          <div style={{ flex: 1, paddingLeft: 10 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div className="chat-inline">{chat?.name}</div>
              <button
                className="del"
                onClick={(e) => {
                  e.stopPropagation(); // Prevent triggering onClick of parent button
                  del("solo", chat && chat.phone_number);
                }}
              >
                <img src={Delete} alt="" />
              </button>
            </div>
            <div className="chat-inline" style={{ fontSize: 12 }}>
              {chat?.phone_number}
            </div>
          </div>
        </button>
      ))}
    </div>
  );
}

export default ChatList;
