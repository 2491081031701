import React, { useEffect, useState, useRef } from "react";
import { axiosInstance } from "../../utills";
import Play from "../../assets/play.svg";
import Online from "../../assets/online-bot.svg";
import Ofline from "../../assets/ofline-bot.svg";

const ChatBox = ({ selectedChat, chatList, broadcastChatList, trigger }) => {
  const [input, setInput] = useState("");
  const [messages, setMessages] = useState([]);
  const [bot, updateBot] = useState(true);
  const [userData, updateUserData] = useState([]);
  const [showModal, updateShowModal] = useState(false);
  const [rows, setRows] = useState(1);

  const getChat = (x, type) => {
    console.log("ChtBox- x", x);
    const config = {
      method: "get",
      url:
        type === "solo"
          ? `/get-chat?phone_number=${x}`
          : type === "broadcast"
          ? `/get-broadcast-chat?broadcastId=${x}`
          : ``,
    };
    axiosInstance
      .request(config)
      .then((response) => {
        console.log("get-chat data is", response.data);
        setMessages(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    console.log("message is ", messages);
  }, [messages]);

  useEffect(() => {
    if (selectedChat.type === "solo" || selectedChat.type === "broadcast") {
      setMessages([]);
      let type = selectedChat?.type;
      if (type === "solo") {
        getChat(
          encodeURIComponent(chatList[selectedChat?.index]?.phone_number),
          "solo"
        );
      }
      if (type === "broadcast" && broadcastChatList[selectedChat?.index]) {
        getChat(
          broadcastChatList[selectedChat?.index].broadcastId,
          "broadcast"
        );
      }
    } else {
      setMessages([]);
    }
  }, [selectedChat, trigger]);

  const handleSendMessage = () => {
    const messageWithBr = input.replace(/\n/g, "<br>");
    if (messageWithBr.trim()) {
      if (messageWithBr.length === 0) return;

      if (messages[0]) {
        console.log(
          "handleSendMessage: phone_number is ",
          messages[0].phone_number
        );
        console.log(
          "handleSendMessage: broadcastId is",
          messages[0].broadcastId
        );

        const config = {
          method: "get",
          url: `/send-message?phone_number=${
            messages[0].phone_number
              ? encodeURIComponent(messages[0].phone_number)
              : null
          }&broadcastId=${
            messages[0].broadcastId ? messages[0].broadcastId : null
          }&message=${messageWithBr}&sender=system`,
        };
        axiosInstance
          .request(config)
          .then((response) => {
            console.log("send-message data is", response.data);
            setMessages([...messages, response.data]);
          })
          .catch((error) => {
            console.log(error);
          });
      }
      setInput("");
      setRows(1);
    }
  };

  const messagesRef = useRef(null);

  useEffect(() => {
    if (messagesRef.current) {
      messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
    }
  }, [messages]);

  const showUserInfo = async () => {
    const config = {
      method: "get",
      url: `/users-data?broadcastId=${messages[0].broadcastId}`,
    };
    axiosInstance
      .request(config)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          updateUserData(response.data);
        }
      })
      .catch((error) => {
        alert("Error! Try again later.");
      });
  };

  useEffect(() => {
    console.log(userData);
    if (userData && userData.length > 0) {
      updateShowModal(true);
    }
  }, [userData]);

  useEffect(() => {
    console.log("Show Modal State is ", showModal);
  }, [showModal]);

  const toggleBlockStatus = () => {
    if (messages[0] && messages[0].phone_number) {
      const newBlockStatus =
        messages[0].block_status === false || messages[0].block_status === 0
          ? 1
          : 0;
      const config = {
        method: "post",
        url: "/change-block-status", // Adjust the URL to your actual endpoint
        data: { phone_number: messages[0].phone_number },
      };

      axiosInstance
        .request(config)
        .then((response) => {
          if (response.data.success || response.status === 200) {
            console.log("Block status updated successfully");
            const updatedMessages = messages.map((message) => ({
              ...message,
              block_status: newBlockStatus,
            }));
            setMessages(updatedMessages);
            // Fetch the updated chat (or any updated data)
            // getChat(
            //   messages[0].phone_number,
            //   selectedChat.type === "solo" ? "solo" : "broadcast"
            // );
          } else {
            console.log("Failed to update block status");
          }
        })
        .catch((error) => {
          console.log("Error updating block status:", error);
        });
    }
  };

  return (
    <>
      <div className="chat-container">
        <div
          style={{
            height: 70,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            backgroundColor: "white",
            padding: 12,
          }}
        >
          <div>
            <h5>
              {messages[0] && messages[0].name
                ? messages[0].name
                : messages[0] && messages[0].channelName}
            </h5>
            <h6>
              {messages[0] && messages[0].phone_number
                ? messages[0].phone_number
                : ""}
            </h6>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignSelf: "center",
              padding: 10,
              alignItems: "center",
            }}
          >
            {/* <p style={{ color: "black", marginBottom: 0 }}>AI Chat</p> */}
            <button
              style={{ borderWidth: 0, backgroundColor: "white" }}
              onClick={() => updateBot(!bot)}
            >
              {bot ? (
                <img src={Online} alt="online" />
              ) : (
                <img src={Ofline} alt="ofline" />
              )}
            </button>
            &nbsp;&nbsp;
            {messages[0] && messages[0].channelName ? (
              <button
                style={{
                  width: 18,
                  height: 18,
                  borderRadius: 9,
                  borderWidth: 0,
                  backgroundColor: "#9ecfe8",
                  color: "black",
                  fontSize: 12,
                  fontWeight: 700,
                }}
                onClick={() => showUserInfo()}
              >
                i
              </button>
            ) : null}
            &nbsp;&nbsp;
            {messages[0] && messages[0].name ? (
              messages[0].block_status === false ||
              messages[0].block_status === 0 ? (
                <button
                  className="btn btn-sm"
                  style={{ backgroundColor: "tomato", color: "white" }}
                  onClick={toggleBlockStatus}
                >
                  Block User
                </button>
              ) : (
                <button
                  className="btn btn-sm"
                  style={{ backgroundColor: "#04aa6d", color: "white" }}
                  onClick={toggleBlockStatus}
                >
                  Unblock User
                </button>
              )
            ) : (
              <></>
            )}
          </div>
        </div>

        <div className="message-list" ref={messagesRef}>
          <div style={{ padding: 10 }}>
            {messages
              ?.filter((message) => message.message !== null)
              .map((message, index) => (
                <div
                  key={message.timestamp}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent:
                      message.sender === "system" || message.sender === "ai"
                        ? "flex-end"
                        : "flex-start",
                  }}
                >
                  <div
                    className={`message ${message.sender}`}
                    style={{ maxWidth: "90%", whiteSpace: "pre-wrap" }}
                  >
                    {message.message?.replace(/<br>/g, "\n")}
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="message-input">
          <textarea
            className="form-control"
            value={input}
            onChange={(e) => {
              const numberOfLines = e.target.value.split("\n").length;
              const updatedRows = Math.min(numberOfLines, 4);
              setInput(e.target.value);
              setRows(updatedRows);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                handleSendMessage();
              }
            }}
            rows={rows}
            placeholder="Type a message..."
            disabled={
              messages[0] && messages[0].status === "Opt-Out" ? true : false
            }
          />
          <button onClick={handleSendMessage}>Send</button>
        </div>
      </div>
      {showModal ? (
        <>
          <div
            style={{
              width: "100vw",
              height: "100vh",
              position: "absolute",
              zIndex: 10,
              top: 0,
              left: 0,
              backgroundColor: "rgba(0,0,0,0.2)",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "100%",
                maxWidth: 500,
                backgroundColor: "white",
                borderRadius: "0.3rem",
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: "rgba(0,0,0,0.2)",
              }}
            >
              <div style={{ padding: "1rem" }}>
                <h5>Broadcast Users List</h5>
              </div>
              <div
                style={{ padding: "1rem", maxHeight: 500, overflowY: "auto" }}
              >
                <table style={{ width: "100%" }}>
                  {userData.map((user, index) => (
                    <tr style={{ color: "black" }} key={index}>
                      <td>{user.name}</td>
                      <td>{user.phoneNumber}</td>
                    </tr>
                  ))}
                </table>
              </div>
              <div
                style={{
                  padding: "1rem",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => updateShowModal(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default ChatBox;
