// Dashboard.jsx
import React, { useEffect, useState } from "react";
import { axiosInstance } from "../utills";
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  Filler,
} from "chart.js";
import { Line, Bar, Pie } from "react-chartjs-2";

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  Filler
);

function Dashboard() {
  // Existing state variables
  const [userCountGraphData, setUserCountGraphData] = useState([]);
  const [doctorCountGraphData, setDoctorCountGraphData] = useState([]);
  const [bookedAppointmentCountGraphData, setBookedAppointmentCountGraphData] =
    useState([]);

  // New state variables
  const [channels, setChannels] = useState([]);
  const [selectedBroadcastId, setSelectedBroadcastId] = useState("All");
  const [data, updateData] = useState(null);

  // New state variables for user activity over time
  const [userActivityData, setUserActivityData] = useState([]);
  const [activityPeriod, setActivityPeriod] = useState("daily"); // Default period is 'daily'

  // New state variable for lead status distribution
  const [leadStatusData, setLeadStatusData] = useState([]);

  useEffect(() => {
    fetchBroadcastChannels();
    fetchUserCountGraphData();
    fetchDoctorCountGraphData();
    fetchLeadStatusDistribution(); // Fetch lead status distribution data
    fetchBookedAppointmentGraphData();
    fetchUserActivityOverTime(activityPeriod); // Fetch user activity data
  }, []);

  useEffect(() => {
    // Fetch KPI stats whenever the selected broadcastId changes
    fetchKpiStats(selectedBroadcastId);
  }, [selectedBroadcastId]);

  useEffect(() => {
    // Fetch user activity data whenever the activityPeriod changes
    fetchUserActivityOverTime(activityPeriod);
  }, [activityPeriod]);

  const fetchBroadcastChannels = () => {
    let config = {
      method: "get",
      url: "/get-broadcast-channels",
    };

    axiosInstance
      .request(config)
      .then((response) => {
        const channels = response.data; // Assuming response data is the array of channels
        setChannels(channels);
      })
      .catch((error) => {
        console.log("Error fetching channels:", error);
      });
  };

  const fetchKpiStats = (broadcastId) => {
    let config = {
      method: "post",
      url: "/get-kpi-stats",
      data: {
        broadcastId: broadcastId,
      },
    };

    axiosInstance
      .request(config)
      .then((response) => {
        updateData(response.data);
      })
      .catch((error) => {
        console.log("Error fetching KPI stats:", error);
      });
  };

  const fetchUserActivityOverTime = (period) => {
    let config = {
      method: "get",
      url: "/user_activity_over_time",
      params: {
        period: period,
      },
    };

    axiosInstance
      .request(config)
      .then((response) => {
        const activityData = response.data.data; // Assuming response data is in the format { data: [...] }
        setUserActivityData(activityData);
      })
      .catch((error) => {
        console.log("Error fetching user activity data:", error);
      });
  };

  const fetchLeadStatusDistribution = () => {
    let config = {
      method: "get",
      url: "/lead_status_distribution",
    };

    axiosInstance
      .request(config)
      .then((response) => {
        const leadData = response.data.data; // Assuming response data is in the format { data: [...] }
        // Exclude entries with lead_status "Unknown"
        const filteredData = leadData.filter(
          (item) => item.lead_status !== "Unknown"
        );
        setLeadStatusData(filteredData);
      })
      .catch((error) => {
        console.log("Error fetching lead status distribution data:", error);
      });
  };

  const handleChannelChange = (event) => {
    const broadcastId = event.target.value;
    setSelectedBroadcastId(broadcastId);
    // fetchKpiStats will be called automatically due to the useEffect dependency
  };

  const handleActivityPeriodChange = (event) => {
    const period = event.target.value.toLowerCase();
    setActivityPeriod(period);
    // fetchUserActivityOverTime will be called automatically due to the useEffect dependency
  };

  // Existing fetch functions
  const fetchUserCountGraphData = () => {
    let config = {
      method: "get",
      url: "/dashboard/graph/get-graph-user-data",
    };

    axiosInstance
      .request(config)
      .then((response) => {
        const {
          data: {
            data: { monthWiseUserCounts },
          },
        } = response;

        setUserCountGraphData(monthWiseUserCounts);
      })
      .catch((error) => {
        console.log("Error fetching user count graph data:", error);
      });
  };

  const fetchDoctorCountGraphData = () => {
    let config = {
      method: "get",
      url: "/dashboard/graph/get-graph-doctors-data",
    };

    axiosInstance
      .request(config)
      .then((response) => {
        const {
          data: {
            data: { monthWiseUserCounts },
          },
        } = response;

        setDoctorCountGraphData(monthWiseUserCounts);
      })
      .catch((error) => {
        console.log("Error fetching doctor count graph data:", error);
      });
  };

  const fetchBookedAppointmentGraphData = () => {
    let config = {
      method: "get",
      url: "/dashboard/graph/get-graph-appointment-data",
    };

    axiosInstance
      .request(config)
      .then((response) => {
        const {
          data: {
            data: { monthWiseAppointmentCounts },
          },
        } = response;

        setBookedAppointmentCountGraphData(monthWiseAppointmentCounts);
      })
      .catch((error) => {
        console.log("Error fetching booked appointment graph data:", error);
      });
  };

  // Removed fetchSubscribeUserCountGraphData and fetchRevenueGeneratedGraphData

  const colClassName = data?.block_count === null ? "col-md-4" : "col-md-3";

  // Define color mapping for lead statuses
  const colorMapping = {
    Completed: "rgb(4, 170, 109)",
    Pending: "rgb(255, 165, 0)",
    Rescheduled: "rgb(255, 99, 71)",
  };

  return (
    <div className="main  scrollView">
      <div className="row" style={{ marginTop: 12 }}>
        <div
          className="col-md-4"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <p style={{ color: "black", margin: 0 }}>Channels:&nbsp;&nbsp;</p>
          <select
            className="form form-control"
            value={selectedBroadcastId}
            onChange={handleChannelChange}
          >
            <option value="All">All</option>
            {channels.map((channel) => (
              <option key={channel.broadcastId} value={channel.broadcastId}>
                {channel.channelName}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="row" style={{ padding: 12, paddingBottom: 0 }}>
        <div className={colClassName} style={topDivs}>
          <div style={outerTopDiv}>
            <p
              style={{
                color: "black",
                margin: 0,
                padding: 12,
                fontSize: 12,
                textAlign: "center",
              }}
            >
              Broadcast Outreach
            </p>
            <p
              style={{
                fontSize: 34,
                color: "black",
                margin: 12,
                textAlign: "center",
              }}
            >
              {data?.message_count ?? 0}
            </p>
            <p style={{ textAlign: "center", fontSize: 12, color: "grey" }}>
              Unique Users
            </p>
          </div>
        </div>
        <div className={colClassName} style={topDivs}>
          <div style={outerTopDiv}>
            <p
              style={{
                color: "black",
                margin: 0,
                padding: 12,
                fontSize: 12,
                textAlign: "center",
              }}
            >
              User Response
            </p>
            <p
              style={{
                fontSize: 34,
                color: "black",
                margin: 12,
                textAlign: "center",
              }}
            >
              {data?.activity_count ?? 0}
            </p>
            <p style={{ textAlign: "center", fontSize: 12, color: "grey" }}>
              Based on Last Activity Date
            </p>
          </div>
        </div>
        <div className={colClassName} style={topDivs}>
          <div style={outerTopDiv}>
            <p
              style={{
                color: "black",
                margin: 0,
                padding: 12,
                fontSize: 12,
                textAlign: "center",
              }}
            >
              Opted-Out Users
            </p>
            <p
              style={{
                fontSize: 34,
                color: "black",
                margin: 12,
                textAlign: "center",
              }}
            >
              {data?.opt_out_count ?? 0}
            </p>
            <p style={{ textAlign: "center", fontSize: 12, color: "grey" }}>
              Users Unsubscribed
            </p>
          </div>
        </div>
        {data?.block_count !== null && (
          <div className="col-md-3" style={topDivs}>
            <div style={outerTopDiv}>
              <p
                style={{
                  color: "black",
                  margin: 0,
                  padding: 12,
                  fontSize: 12,
                  textAlign: "center",
                }}
              >
                Blocked Users
              </p>
              <p
                style={{
                  fontSize: 34,
                  color: "black",
                  margin: 12,
                  textAlign: "center",
                }}
              >
                {data?.block_count ?? 0}
              </p>
              <p style={{ textAlign: "center", fontSize: 12, color: "grey" }}>
                Restricted on Platform
              </p>
            </div>
          </div>
        )}
      </div>

      <div className="row" style={{ padding: 12 }}>
        {/* User Activity Over Time Chart */}
        <div
          className="col-md-8"
          style={{ padding: 10, maxHeight: 350, paddingTop: 0 }}
        >
          <div
            className="dashboard-card"
            style={{ backgroundColor: "white", padding: 20, paddingBottom: 50 }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 10,
                justifyContent: "space-between",
              }}
            >
              <h5 style={{ margin: 0, marginRight: 10 }}>
                User Activity Over Time
              </h5>
              <select
                className="form-control"
                style={{ width: "auto" }}
                value={activityPeriod}
                onChange={handleActivityPeriodChange}
              >
                <option value="daily">Daily</option>
                <option value="weekly">Weekly</option>
                <option value="monthly">Monthly</option>
              </select>
            </div>
            <Line
              options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  title: {
                    display: false,
                  },
                },
                scales: {
                  x: {
                    title: {
                      display: true,
                      text: "Period",
                    },
                  },
                  y: {
                    title: {
                      display: true,
                      text: "Active Users",
                    },
                  },
                },
              }}
              data={{
                labels: userActivityData.map(({ period }) => period),
                datasets: [
                  {
                    fill: true,
                    label: "Active Users",
                    data: userActivityData.map(
                      ({ active_users }) => active_users
                    ),
                    borderColor: "rgb(31, 154, 214)",
                    backgroundColor: "rgba(31, 154, 214, 0.5)",
                  },
                ],
              }}
            />
          </div>
        </div>

        {/* Lead Status Distribution Chart (Pie Chart) */}
        <div
          className="col-md-4"
          style={{ maxHeight: 350, padding: 10, paddingTop: 0 }}
        >
          <div
            className="dashboard-card"
            style={{ backgroundColor: "white", padding: 20, paddingBottom: 50 }}
          >
            <h5 style={{ marginBottom: 20 }}>Lead Status Distribution</h5>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "flex-start",
                height: "100%",
              }}
            >
              <Pie
                data={{
                  labels: leadStatusData.map(({ lead_status }) => lead_status),
                  datasets: [
                    {
                      data: leadStatusData.map(({ user_count }) => user_count),
                      backgroundColor: leadStatusData.map(
                        (item) =>
                          colorMapping[item.lead_status] || "#4BC0C0" // Default color if lead_status not in mapping
                      ),
                      hoverBackgroundColor: leadStatusData.map(
                        (item) =>
                          colorMapping[item.lead_status] || "#4BC0C0"
                      ),
                    },
                  ],
                }}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      position: "right", // Legend on the right
                    },
                    tooltip: {
                      callbacks: {
                        label: function (context) {
                          const label = context.label || "";
                          const value = context.parsed;
                          return `${label}: ${value}`;
                        },
                      },
                    },
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row" style={{ padding: 12 }}>
        {/* Number of Users Chart */}
        <div className="col-md-6" style={{ aspectRatio: 16 / 9, padding: 10 }}>
          <div className="dashboard-card ">
            <Line
              options={{
                responsive: true,
                plugins: {
                  title: {
                    display: true,
                    text: "Number of Users",
                  },
                },
              }}
              data={{
                labels: doctorCountGraphData?.map(({ month }) => month),
                datasets: [
                  {
                    fill: true,
                    label: "Number of Doctors",
                    data: doctorCountGraphData?.map(({ count }) => count),
                    borderColor: "rgb(53, 162, 235)",
                    backgroundColor: "rgba(53, 162, 235, 0.5)",
                  },
                  {
                    fill: true,
                    label: "Number of Users",
                    data: userCountGraphData?.map(({ count }) => count),
                    borderColor: "rgb(255, 99, 132)",
                    backgroundColor: "rgba(255, 99, 132, 0.5)",
                  },
                ],
              }}
            />
          </div>
        </div>

        {/* Appointment Booked Chart */}
        <div className="col-md-6" style={{ aspectRatio: 16 / 9, padding: 10 }}>
          <div className="dashboard-card">
            <Bar
              options={{
                responsive: true,
                plugins: {
                  title: {
                    display: true,
                    text: "Appointments Booked",
                  },
                },
              }}
              data={{
                labels: bookedAppointmentCountGraphData?.map(
                  ({ month }) => month
                ),
                datasets: [
                  {
                    fill: true,
                    label: "Appointments",
                    data: bookedAppointmentCountGraphData?.map(
                      ({ count }) => count
                    ),
                    borderColor: "rgb(31, 154, 214)",
                    backgroundColor: "rgba(31, 154, 214, 0.5)",
                  },
                ],
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

const topDivs = {
  padding: 5,
};
const outerTopDiv = {
  border: "1px solid rgba(0,0,0,0.1)",
  backgroundColor: "white",
  boxShadow: "0 3px 6px rgba(0, 0, 0, 0.05)",
  borderRadius: 5,
};
export default Dashboard;
